import React from "react"

import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/products.css"


import img1 from '../images/product12/DSC_0001.webp'
import img2 from '../images/product12/DSC_0002.webp'
import img3 from '../images/product12/DSC_0003.webp'
import img4 from '../images/product12/DSC_0004.webp'
import img5 from '../images/product12/DSC_0005.webp'
import img6 from '../images/product12/DSC_0006.webp'
import img7 from '../images/product12/DSC_0007.webp'

import raty from '../images/raty-banner.jpg'

import ImageGallery from 'react-image-gallery';
 
const images = [
  {
    original: img1,
    thumbnail: img1,
  },
  {
    original: img2,
    thumbnail: img2,
  },
  {
    original: img3,
    thumbnail: img3,
  },
  {
    original: img4,
    thumbnail: img4,
  },
  {
    original: img5,
    thumbnail: img5,
  },
  {
    original: img6,
    thumbnail: img6
  },
  {
    original: img7,
    thumbnail: img7,
  }
];

const ProductView12 = () => {

  const price = 10999;


  return(
  <Layout>
    <SEO 
      title="Sauna ogrodowa z przedsionkiem" 
      description = { SEO.description }
      urlName="sauna-ogrodowa-z-przedsionkiem"
    />
    <div className="banner">
        <div className="bannerPlate">
          <p>Sauna ogrodowa</p>
        </div>
    </div>
    <div className="productViewPlate" style={{marginBottom: 100}}>

      <div className="gallery">
        <ImageGallery slideInterval="2000"  items={images} />
      </div>
      <div className="productDescription">
          <div id="prodTop"></div>
          <div id="prodLeft"></div>
          <div id="prodBottom"></div>
          <div id="prodRight"></div>
          <div id="productTitle">Sauna ogrodowa z przedsionkiem</div>
          <div id="productPrice">{price}zł
          </div>
          <div className="productOption">
              <div className="productDescriptionText">
                  <h3>Opis</h3>
                  <p>
                    Sauna ogrodowa w kształcie beczki wykonana z suszonego drewna świerkowego lub modrzewiowego. Jest to doskonała alternatywa na relaks w wolnym czasie. Sauna jest w pełni skompletowana oraz posiada przedsionek. Istnieje możliwość zakupu produktu na raty. Produkty Derw-Haus to gwarancja trwałości na długie lata!<br/>
                  </p>
                  <h3>Dane techniczne:</h3>
                  <ul>
                    <li>Dach sauny pokryty gontem</li>
                    <li>Drzwi wykonane z drewna z hartowaną szybą</li>
                    <li>Ławki</li>
                    <li>sauna wyposażona w ławki oraz podłogę</li>
                    <li>średnica: 200cm</li>
                    <li>Długość: 245cm</li>
                    <li>Grubość ścian: 45 mm</li>
                    <li>Przedsionek</li>
                  </ul>
                  <h3>Dodatkowe konfiguracje</h3>
                  <ul>
                    <li>Przeszklenie panoramiczne z szyby hartowanej, przyciemnionej 8mm 2500zł</li>
                    <li>Przeszklenie pół panoramiczne z szyby hartowanej, przyciemnionej 8mm 2000zł</li>
                  </ul>
                  <a href="https://epos.inbank.pl/pl/drewhaus">
                  <img src={raty} className="creditBanner" alt="domek-ogrodowy-raty"/>
                  </a>
                  <p><b>Domki ogrodowe możesz obejrzeć w Gnieźnie! Zajrzyj do zakładki&nbsp; 
                    <Link to="/contact" style={{ color: '#765F52' }} >
                      kontakt
                    </Link> i sprawdź gdzie nas znajdziesz!</b></p>
              </div>
          </div>
      </div>
    </div>
  </Layout>
  )}

export default ProductView12


